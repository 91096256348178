import styled from 'styled-components'
import { TextStyles, defaultSectionTheme } from '@/constants'
import Typography from '@/components/Typography/Typography'
import { styledText } from '@/components/Typography/Typography.styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  background-color: ${({ theme }) => theme.sectionTheme?.background || defaultSectionTheme.background};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    gap: 44px;
  }
`

const ContentSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const RightContentSection = styled(ContentSection)``

export const LeftContentSection = styled(ContentSection)`
  display: flex;
  gap: 24px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    gap: 32px;
  }
`

export const MainContent = styled.div<{ $isReverse?: boolean }>`
  display: grid;
  grid-template-columns: auto;
  gap: 32px;

  ${LeftContentSection} {
    order: 2;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    grid-template-columns: auto auto;
    align-items: flex-start;
    grid-gap: 40px;

    ${RightContentSection} {
      order: 1;
    }

    ${LeftContentSection} {
      order: ${({ $isReverse }) => ($isReverse ? 2 : 1)};
    }
  }
`

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const ContentTitle = styled(Typography).attrs({
  variant: TextStyles['Heading 2'],
})`
  color: ${({ theme }) => theme.sectionTheme?.text};
`

export const StyledIcon = styled.img`
  width: 48px;
  height: 48px;
  margin-bottom: -16px;
`

export const SubCopy = styled(Typography).attrs({
  variant: TextStyles['Large Paragraph Text'],
})`
  color: ${({ theme }) => theme.sectionTheme?.subText};
`

export const SubPoints = styled.ul`
  padding: 0;
  margin: 0 0 0 16px;

  > li {
    ${styledText[TextStyles['Large Paragraph Text']]}
    color: ${({ theme }) => theme.sectionTheme?.subText};
  }

  > li + li {
    margin-top: 24px;
  }
`

export const SubpointItem = styled.span`
  margin-left: -2px;
`

export const PropositionList = styled.ul`
  padding: 0;
  margin: 0;
  display: grid;
  grid-gap: 24px;
  list-style-type: none;

  li {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    grid-gap: 40px;
    grid-template-columns: auto auto auto;
  }
`

export const PropositionLiContainer = styled.div`
  & svg {
    width: 24px;
    height: 24px;
  }

  svg {
    path {
      fill: ${({ theme }) => theme.sectionTheme?.text};
    }
  }
`

export const PropositionLiContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const PropositionTitle = styled(Typography).attrs({
  variant: TextStyles['Heading 3'],
})`
  color: ${({ theme }) => theme.sectionTheme?.text};
`

export const PropositionSubTitle = styled(Typography).attrs({
  variant: TextStyles['Large Paragraph Text']
})`
  color: ${({ theme }) => theme.sectionTheme?.subText};
`

export const StyledProductMockup = styled.div`
  position: relative;
  width: inherit;
  border-radius: 4px;
`

export const ProductMockupImage = styled.img`
  width: 100%;
`
