import type{ ProductUseCaseProps } from '@/interfaces/productUseCase'
import {
  AssetRenderer,
  LocalThemeProvider,
  NewButton,
} from '@/components'
import {
  MainContent,
  ContentHeader,
  ContentTitle,
  LeftContentSection,
  RightContentSection,
  StyledIcon,
  StyledProductMockup,
  SubPoints,
  Container,
  PropositionList,
  PropositionTitle,
  PropositionSubTitle,
  PropositionLiContentWrapper,
  PropositionLiContainer,
  SubpointItem,
  ProductMockupImage,
  SubCopy,
} from './UseCaseItemNewFramework.styles'
import Check from '../../../../../../public/images/check-icon.svg'
import { useGetSectionTheme } from '@/hooks'
import { ISectionTheme } from '@/interfaces/theme'

interface UseCaseItemNewFrameworkProps extends ProductUseCaseProps {
  wrapperCustomTheme?: ISectionTheme
}

const UseCaseItemNewFramework: React.FC<UseCaseItemNewFrameworkProps> = ({
  title,
  icon,
  theme,
  customTheme,
  subCopy,
  subCopyLong,
  alignment,
  subPoints,
  buttonCopy,
  openInNewTab,
  buttonLink,
  propositionList,
  button,
  productMockup,
  wrapperCustomTheme,
}) => {
  const sectionTheme = useGetSectionTheme(theme, customTheme, wrapperCustomTheme)

  return (
    <LocalThemeProvider theme={{ sectionTheme }}>
      <Container>
        <MainContent $isReverse={alignment === 'Right'}>
          <LeftContentSection>
            <ContentHeader>
              {icon?.url && (
                <StyledIcon src={icon?.url} alt={icon?.description} />
              )}

              <ContentTitle>{title}</ContentTitle>

              {(subCopy || subCopyLong) && <SubCopy>{subCopyLong || subCopy}</SubCopy>}

              {subPoints?.items.length > 2 && (
                <SubPoints>
                  {subPoints.items.map((subPoint, index) => (
                    <li key={index}>
                      <SubpointItem>{subPoint.desc}</SubpointItem>
                    </li>
                  ))}
                </SubPoints>
              )}

              <NewButton
                buttonVariant={button?.buttonVariant || 'Outlined'}
                buttonColor={button?.buttonColor || 'Secondary'}
                buttonIcon={button?.buttonIcon}
                buttonIconPosition={button?.buttonIconPosition}
                href={buttonLink}
                openInNewTab={openInNewTab}
              >
                {buttonCopy}
              </NewButton>
            </ContentHeader>
          </LeftContentSection>

          <RightContentSection>
            <StyledProductMockup>
              <AssetRenderer
                asset={productMockup}
                ImageComponent={ProductMockupImage}
              />
            </StyledProductMockup>
          </RightContentSection>
        </MainContent>

        {propositionList && (
          <PropositionList>
            {propositionList.map((item, index) => (
              <li key={index}>
                <PropositionLiContainer>
                  <Check />
                </PropositionLiContainer>

                <PropositionLiContentWrapper>
                  <PropositionTitle>{item.title}</PropositionTitle>
                  <PropositionSubTitle>{item.description}</PropositionSubTitle>
                </PropositionLiContentWrapper>
              </li>
            ))}
          </PropositionList>
        )}
      </Container>
    </LocalThemeProvider>
  )
}

export default UseCaseItemNewFramework
