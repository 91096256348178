import styled from 'styled-components'
import SectionWrapper from '@/components/SectionWrapper'
import { defaultSectionTheme } from '@/constants'

export const Wrapper = styled(SectionWrapper)`
  background-color: ${({ theme }) =>
    theme.sectionTheme?.background || defaultSectionTheme.background};
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
