// Component
import {
  UseCaseWrapper,
  UseCaseInnerContent,
  UseCaseListWrapper,
  FirstConnectorLine,
  SecondConnectorLine,
  Divider,
  BannerListWrapper,
  BannerItem,
  ConnectorLineBeforeFirst,
  ConnectorLineAfterLast,
  ProductFeaturesWrapper,
} from './ProductFeatures.styles'
import {
  Banner,
  Container,
  LocalThemeProvider,
  ProductUseCase,
} from '@/components'
import { SectionSeparator } from '@/components/WrapperContentItems/components'
import PrimaryTitle from '../../components/PrimaryTitle'
import SecondaryTitle from '../../components/SecondaryTitle'
import ProductFeaturesNewFramework from './ProductFeaturesNewFramework'
import { useTheme } from 'styled-components'

// Interface
import { ProductUseCaseProps } from '@/interfaces/productUseCase'
import { BannerProps } from '@/interfaces/banner'
import ContentTypeKeys from '@/interfaces/contentType'
import { SectionThemeKey } from '@/interfaces/sectionThemeKey'

// Util
import { capitalize } from '@/utils'

// Hooks
import { useGetSectionTheme } from '@/hooks'
import { ISectionTheme } from '@/interfaces/theme'

enum OppositeAlignment {
  Left = 'Right',
  Right = 'Left'
}

const UseCaseItem: React.FC<{
  item: ProductUseCaseProps
  index: number
  itemListLength: number
  itemList: ProductUseCaseProps[]
  showConnectorLines?: boolean
  showFirstConnectorLine?: boolean
  showLastConnectorLine?: boolean
  wrapperCustomTheme?: ISectionTheme
}> = ({
  item,
  index,
  itemListLength,
  itemList,
  showConnectorLines = false,
  showFirstConnectorLine = false,
  showLastConnectorLine = false,
  wrapperCustomTheme,
}) => {
  const sectionTheme = useGetSectionTheme(item.theme, item.customTheme, wrapperCustomTheme)

  return (
    <LocalThemeProvider
      theme={{ sectionTheme }}
    >
      <UseCaseWrapper isLastItemShowConnectorLine={!!(index === itemListLength - 1 && showLastConnectorLine)}>
        <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
          <UseCaseInnerContent>
            {item?.productMockup && showConnectorLines && (
              <FirstConnectorLine
                isShow={!!(index !== itemListLength - 1 || showLastConnectorLine)}
                alignment={item?.alignment}
              />
            )}
            <ProductUseCase
              {...item}
              isStandalone={itemListLength === 1}
              showVerticalConnectorLine={!!showConnectorLines}
            />
            {item?.theme === itemList[index + 1]?.theme && <Divider />}
            {item?.productMockup && showConnectorLines && (
              <SecondConnectorLine
                isShow={!!(index !== 0 || showFirstConnectorLine)}
                alignment={item?.alignment}
              />
            )}
          </UseCaseInnerContent>
        </Container>
      </UseCaseWrapper>
    </LocalThemeProvider>
  )
}

const UseCaseList: React.FC<{
  itemList?: ProductUseCaseProps[]
  showConnectorLines?: boolean
  showFirstConnectorLine?: boolean
  showLastConnectorLine?: boolean
  wrapperCustomTheme?: ISectionTheme
}> = ({
  itemList,
  showConnectorLines,
  showFirstConnectorLine = false,
  showLastConnectorLine = false,
  wrapperCustomTheme,
}) => {
  const renderConnectorLineBeforeFirst = () => {
    const firstItem = itemList?.[0]
    if (
      firstItem?.productMockup &&
      showConnectorLines &&
      showFirstConnectorLine
    ) {
      return (
        <Container style={{ position: 'relative' }}>
          <ConnectorLineBeforeFirst
            isShow
            alignment={OppositeAlignment[firstItem.alignment]}
          />
        </Container>
      )
    }
  }

  const renderConnectorLineAfterLast = () => {
    const lastItem = itemList?.[itemList.length - 1]
    if (
      lastItem?.productMockup &&
      showConnectorLines &&
      showLastConnectorLine
    ) {
      return (
        <Container style={{ position: 'relative' }}>
          <ConnectorLineAfterLast
            isShow
            alignment={OppositeAlignment[lastItem.alignment]}
          />
        </Container>
      )
    }
  }

  return (
    <UseCaseListWrapper>
      {renderConnectorLineBeforeFirst()}
      {itemList?.map((item, index) => (
        <UseCaseItem
          key={index}
          item={item}
          index={index}
          itemListLength={itemList.length}
          itemList={itemList}
          showConnectorLines={showConnectorLines}
          showFirstConnectorLine={showFirstConnectorLine}
          showLastConnectorLine={showLastConnectorLine}
          wrapperCustomTheme={wrapperCustomTheme}
        />
      ))}
      {renderConnectorLineAfterLast()}
    </UseCaseListWrapper>
  )
}

export interface ProductFeaturesProps {
  primaryTitle: string
  secondaryTitle?: string
  itemList: (ProductUseCaseProps | BannerProps)[]
  showConnectorLines?: boolean
  showFirstConnectorLine?: boolean
  showLastConnectorLine?: boolean
  theme?: SectionThemeKey
  customTheme?: ISectionTheme
  withSeparator?: boolean
}

const ProductFeatures: React.FC<ProductFeaturesProps> = (props) => {
  const {
    primaryTitle,
    secondaryTitle,
    itemList,
    showConnectorLines,
    showFirstConnectorLine,
    showLastConnectorLine,
    theme,
    customTheme,
    withSeparator,
  } = props
  const { pageTheme } = useTheme()
  const isNewProductFramework = pageTheme !== undefined
  if (isNewProductFramework) {
    return <ProductFeaturesNewFramework {...props} />
  }

  const itemListCollection = itemList?.filter(Boolean)
  const isEveryItemBannerType = itemListCollection.every(
    (item) => capitalize(item.__typename) === ContentTypeKeys.Banner
  )

  const sectionTheme = useGetSectionTheme(theme, customTheme)
  const lastItemTheme = useGetSectionTheme(itemList[itemList.length - 1].theme, itemList[itemList.length - 1].customTheme)

  return (
    <LocalThemeProvider theme={{ sectionTheme }}>
      <ProductFeaturesWrapper
        lastItemBackground={lastItemTheme.background}
        showConnectorLines={showConnectorLines}
        showFirstConnectorLine={showFirstConnectorLine}
        showLastConnectorLine={showLastConnectorLine}
      >
        {secondaryTitle && (
          <SecondaryTitle>{secondaryTitle}</SecondaryTitle>
        )}
        <PrimaryTitle noMarginBottom={isEveryItemBannerType}>
          {primaryTitle}
        </PrimaryTitle>
        {isEveryItemBannerType ? (
          <BannerListWrapper>
            <Container>
              {(itemList as BannerProps[]).map((item, index) => (
                <BannerItem
                  key={index}
                  hasHeroImage={!!item.heroImages?.length}
                  noTitle={!primaryTitle && !secondaryTitle}
                >
                  <Banner
                    {...item}
                    noPadding
                    noLocalTheme
                  />
                </BannerItem>
              ))}
            </Container>
          </BannerListWrapper>
        ) : (
          <UseCaseList
            itemList={itemList as ProductUseCaseProps[]}
            showConnectorLines={showConnectorLines}
            showFirstConnectorLine={showFirstConnectorLine}
            showLastConnectorLine={showLastConnectorLine}
            wrapperCustomTheme={customTheme}
          />
        )}
      </ProductFeaturesWrapper>
      {withSeparator && <SectionSeparator />}
    </LocalThemeProvider>
  )
}

export default ProductFeatures
