import React from 'react'
import { ProductFeaturesProps } from '../ProductFeatures'
import { Container, LocalThemeProvider } from '@/components'
import { Wrapper, ContentWrapper } from './ProductFeaturesNewFramework.styles'
import UseCaseItemNewFramework from './UseCaseItemNewFramework'
import { ProductUseCaseProps } from '@/interfaces/productUseCase'
import { PrimaryTitle } from '@/components/WrapperContentItems/components'
import { useGetSectionTheme } from '@/hooks'

const ProductFeaturesNewFramework: React.FC<ProductFeaturesProps> = ({
  primaryTitle,
  itemList,
  theme,
  customTheme,
}) => {
  const sectionTheme = useGetSectionTheme(theme, customTheme)
  const productUseCase = itemList[0] as ProductUseCaseProps

  return (
    <LocalThemeProvider theme={{ sectionTheme }}>
      <Wrapper>
        <Container>
          <ContentWrapper>
            <PrimaryTitle>{primaryTitle}</PrimaryTitle>
            <UseCaseItemNewFramework {...productUseCase} wrapperCustomTheme={customTheme} />
          </ContentWrapper>
        </Container>
      </Wrapper>
    </LocalThemeProvider>
  )
}

export default ProductFeaturesNewFramework
