import styled, { css } from 'styled-components'

import { defaultSectionTheme } from '@/constants'
import { VerticalConnectorLine } from '@/components/ProductUseCase/ProductUseCase.styles'
import SectionWrapper, { SECTION_WRAPPER_PADDING_DESKTOP, SECTION_WRAPPER_PADDING_MOBILE } from '@/components/SectionWrapper/SectionWrapper.styles'

const useCaseWrapperPaddingBottom = 38

export const ProductFeaturesWrapper = styled(SectionWrapper)<{
  lastItemBackground: string
  showConnectorLines?: boolean
  showFirstConnectorLine?: boolean
  showLastConnectorLine?: boolean
}>`
  /* The wrapper background needs to be seperated into two parts to match the background color of children:
    1. Bottom: follows the last item theme (takes up SECTION_WRAPPER_PADDING_DESKTOP)
    2. Top: follows the section theme (takes up the remainder)
  */
  background: ${({ theme, lastItemBackground }) => `
    linear-gradient(to top,
    ${lastItemBackground} ${SECTION_WRAPPER_PADDING_DESKTOP}px,
    ${theme.sectionTheme?.background || defaultSectionTheme.background} ${SECTION_WRAPPER_PADDING_DESKTOP}px)
  `};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${(props) => props.showConnectorLines && props.showFirstConnectorLine && css`
      padding-top: 0;
    `}

    ${(props) => props.showConnectorLines && props.showLastConnectorLine && css`
      padding-bottom: 0;
    `}
  }
`

export const UseCaseWrapper = styled.div<{ isLastItemShowConnectorLine: boolean }>`
  padding-top: ${SECTION_WRAPPER_PADDING_MOBILE}px;
  background: ${({ theme }) => theme.sectionTheme?.background};

  :last-of-type {
    padding-bottom: 0;
  }

  :first-of-type {
    padding-top: 0;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-bottom: ${useCaseWrapperPaddingBottom}px;
    padding-top: ${100 - useCaseWrapperPaddingBottom}px;

    ${({ isLastItemShowConnectorLine }) => isLastItemShowConnectorLine && css`
      padding-bottom: 100px;
    `}
  }
`

// This wrapper is to remove a little bit of top padding to match the design
export const UseCaseListWrapper = styled.div`
  ${UseCaseWrapper}:last-child {
    ${VerticalConnectorLine} {
      height: 0 !important;
    }
  }
`

const horizontalLineTop = 76
const useCaseInnerContentPadding = 34

interface UseCaseInnerContentProps {
  isShow: boolean
  alignment: 'Left' | 'Right'
}

export const FirstConnectorLine = styled.div<UseCaseInnerContentProps>`
  position: absolute;
  z-index: 1;
  display: none;

  ${({
    isShow,
    alignment,
    theme,
  }) => isShow && css`
    // This is horizontal connection line
    top: 100%;
    height: ${horizontalLineTop}px;
    border-bottom: 1px solid ${theme.colors.orange};

    ${alignment === 'Left' ? css`
        left: 290px;
        right: ${useCaseInnerContentPadding}px;
        border-left: 1px solid ${theme.colors.orange};
        border-bottom-left-radius: 10px;
      ` : css`
        left: ${useCaseInnerContentPadding}px;
        right: 290px;
        border-right: 1px solid ${theme.colors.orange};
        border-bottom-right-radius: 10px;
      `}
  `}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: block;
  }
`

export const SecondConnectorLine = styled.div<UseCaseInnerContentProps>`
  position: absolute;
  z-index: 1;
  display: none;

  ${({
    isShow,
    alignment,
    theme,
  }) => isShow && css`
    // This is vertical connection line
    top: -${100 - horizontalLineTop + 1}px;
    bottom: calc(100% - 300px);
    width: ${useCaseInnerContentPadding}px;
    border-top: 1px solid ${theme.colors.orange};
    border-bottom: 1px solid ${theme.colors.orange};

    ${alignment === 'Left' ? css`
        left: 0;
        border-left: 1px solid ${theme.colors.orange};
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
      ` : css`
        right: 0;
        border-right: 1px solid ${theme.colors.orange};
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      `}
  `}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: block;
  }
`
export const ConnectorLineBeforeFirst = styled(FirstConnectorLine)`
  height: 38px;
`

export const ConnectorLineAfterLast = styled(SecondConnectorLine)`
  ${({ alignment }) => `
    border-bottom: unset;
    bottom: 100%;

  ${alignment === 'Left'
    ? 'border-bottom-left-radius: unset;'
    : 'border-bottom-right-radius: unset;'}
  `}
`

export const UseCaseInnerContent = styled.div`
  position: relative;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-left: ${useCaseInnerContentPadding}px;
    padding-right: ${useCaseInnerContentPadding}px;
  }
`

export const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.sectionTheme?.dividerColor};
  border-bottom: 1px solid ${({ theme }) => theme.sectionTheme?.dividerColor};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: none;
  }
`

export const BannerListWrapper = styled.div`
  background: ${({ theme }) => theme.sectionTheme?.background};
`

export const BannerItem = styled.div<{ hasHeroImage?: boolean, noTitle?: boolean }>`
  :first-child > div {
    padding-top: 30px;

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      padding-top: 60px;
    }

    ${({ noTitle }) => noTitle && `
      padding-top: 0 !important;
    `}
  }

  > div {
    padding-bottom: 100px;

    ${({ theme, hasHeroImage }) => hasHeroImage && `
      padding-bottom: 50px;

      ${theme.mediaBreakpointDown.sm} {
        > div {
          margin-top: 0;
        }
      }
    `}
  }
`
